import { Box } from '@core';
import { Card } from '@components';
import { CardsBlockSection } from '@containers';
import React from 'react';

const Cards = ({ padding, ...props }) => (
  <Box {...padding}>
    <CardsBlockSection
      {...props}
      itemComponent={({ backgroundColor, ...rest }) => (
        <Box backgroundColor={backgroundColor} m={12} height="95%">
          <Card.Feature {...rest} imageHeight={props.imageHeight || { _: 300, md: 370 }} />
        </Box>
      )}
      itemsOnDesktop={props.items?.length < 3 ? props.items.length : 3}
    />
  </Box>
);

export default Cards;
